import React, { ReactNode, useEffect, useState } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import LenderStatusDevMenu from 'src/components/e2e/lender-status-dev-menu/lender-status-dev-menu';
import { Seo } from 'src/components/seo';
import {
  E2eLogicObjectsWithAasoParams,
  e2eLogicUtils_getRoutingLogicObjects,
} from 'src/e2e-redesign/business-logic/e2e-logic-utils';
import LoadingState from 'src/e2e-redesign/components/LoadingState';
import AutoApprovalCardTable from 'src/e2e-redesign/views/LenderResponses/auto-approval-card-table';
import LenderResponseTabs from 'src/e2e-redesign/views/LenderResponses/lender-offers-tab';
import {
  GroupedOfferI,
  LenderSubmissionStatusE,
  providerResLogic_functionsAndVals,
  providerRespLogic_init,
} from 'src/e2e-redesign/views/LenderResponses/logic/provider-response-logic';
import MoveForwardCard from 'src/e2e-redesign/views/LenderResponses/move-forward-card';
import NewHandleNotOfferCard from 'src/e2e-redesign/views/LenderResponses/new-handle-not-offer-card';
import NewLenderResponseE2eHandler from 'src/e2e-redesign/views/LenderResponses/new-lender-response-e2e-handler';
import NewLenderOfferTable from 'src/e2e-redesign/views/LenderResponses/new-lender-response-table';
import { LendersI } from 'src/interfaces/lenders.interfaces';

import PageIntro from '../../components/PageIntro';
import ThemedContainer from '../../components/ThemedContainer';
import RedirectModal from './RedirectModal';
import ResponseFooter from './ResponseFooter';

/**
 * - Here we will submit the application, but...
 *        - it will depend on the flow... we'll need to create a function that handles it based
 *          on all of the logic objects
 * - create socket connection
 * - get-e2e-submission endpoint
 * - Show the "Not satisfied" card if...
 *    - ? do we show this no matter what if they have another flow?
 * - User clicks on "Accept & Proceed"
 * - If they are all declined...
 *    - if they have other flows, show "Not satisfied" card
 *    - If they don't have other flows, show "We're sorry"
 *
 *
 * Questions:
 * - When do we show the "Not satisfied" card?
 *
 * Resources:
 * - src/pages/e2e/lender-information-review.tsx
 * - Meineke Figma Mocks -> Prime Provider Response Single Flow
 */

export default function NewLenderResponse() {
  // DEV ONLY: State for toggling responses for testing purposes
  const routingPackage: E2eLogicObjectsWithAasoParams = e2eLogicUtils_getRoutingLogicObjects();
  const e2eStore = routingPackage.e2eStore;
  const lenders = e2eStore.lenders;
  providerRespLogic_init(routingPackage);
  const fnv = providerResLogic_functionsAndVals(routingPackage);
  const { handleApiPipe, handleUnhappyPathRouting, handleContinueWaterfallPiping, handleMoveForwardAction } = fnv.fn;
  const {
    offers,
    notOffers,
    showNotHappyCard,
    hasRedirects,
    submittedTabs,
    submissionStatus,
    allDeclined,
    haveE2eLendersResponded,
    showContinueWaterfallPiping,
    showMoveForwardCard,
    showErrorPage,
    combinedResponses,
    autoApprovals,
  } = fnv.v;

  const [openRedirectModal, setOpenRedirectModal] = useState(false);
  const [currentRedirectLender, setCurrentRedirectLender] = useState<LendersI>();
  const [userHasClosed, setUserHasClosed] = useState(false);
  // R:TODO E2E - add this back - ask Brad

  useEffect(() => {
    if (hasRedirects && submittedTabs.length > 0 && lenders && !userHasClosed) {
      const currentRedirectLender = lenders.find((lender) => lender.lender_id === submittedTabs?.[0].lender_id);
      setOpenRedirectModal(true);
      if (currentRedirectLender) {
        setCurrentRedirectLender(currentRedirectLender);
      }
    } else {
      setOpenRedirectModal(false);
    }
  }, [hasRedirects, lenders, submittedTabs]);

  useEffect(() => {
    // whenever the current redirect lender changes, we reset this
    setUserHasClosed(false);
  }, [currentRedirectLender]);

  const getDefaultStatusDetails = (): { t: string; p: string } => {
    switch (submissionStatus) {
      case LenderSubmissionStatusE.submitted:
        return {
          t: `Your application is being processed.`,
          p: `Please wait while we contact the third-party payment options. Please do not refresh or close this page.`,
        };
      case LenderSubmissionStatusE.single_offer:
        return {
          t: `Payment Options`,
          p: `Please review the payment option below.`,
        };
      case LenderSubmissionStatusE.multiple_offers:
        return {
          t: `Payment Options`,
          p: `Please review the payment options below.`,
        };
      case LenderSubmissionStatusE.no_offers:
      default:
        return {
          t: `Payment Options`,
          p: `Please review the payment options below.`,
        };
    }
  };

  const responseDetails = getDefaultStatusDetails();

  if (showErrorPage) {
    responseDetails.p = '';
  }

  const showDevButton = () => {
    if (process.env.REACT_APP_ENVIRONMENT !== 'production') {
      return <LenderStatusDevMenu handleAction={handleApiPipe} cancelAction={() => {}} />;
    }
    return null;
  };

  const closeRedirectModal = () => {
    // we won't let them close
    setOpenRedirectModal(false);
    // to prevent the modal from keep popping up everytime they close it
    setUserHasClosed(true);
  };

  const showCard = (card: ReactNode) => {
    return (
      <Grid item xs={12} md={6}>
        {card}
      </Grid>
    );
  };

  const getProperCard = (groupedOffer: GroupedOfferI, index: number) => {
    if (groupedOffer.is_offer) {
      return <NewLenderResponseE2eHandler groupedOffer={groupedOffer} index={index} routingPackage={routingPackage} />;
    }
    if (groupedOffer.is_offer === false && !groupedOffer.is_auto) {
      return <NewHandleNotOfferCard groupedOffer={groupedOffer} />;
    }
    if (groupedOffer.is_auto) {
      return <AutoApprovalCardTable groupedOffer={groupedOffer} index={index} />;
    }
  };
  const handleRedirectOrOffers = () => {
    if (showErrorPage && (offers.length === 0 || notOffers.length === 0)) {
      return (
        <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
            <Typography variant="body1" style={{ marginTop: '20px' }} paragraph>
              There was an error. Please get with a sales associate to continue.
            </Typography>
          </Grid>
        </Grid>
      );
    }
    if (
      (haveE2eLendersResponded && combinedResponses.length > 0) ||
      (haveE2eLendersResponded && combinedResponses.length > 0)
    ) {
      return (
        <Box>
          <LenderResponseTabs combinedResponses={combinedResponses} />
          <Grid container spacing={2}>
            {combinedResponses.map((groupedOffer, index) => (
              <Grid item xs={12} key={index} id={`lender-${index}`}>
                {getProperCard(groupedOffer, index)}
              </Grid>
            ))}
            {showMoveForwardCard && (
              <Grid item xs={12}>
                <MoveForwardCard onClick={handleMoveForwardAction} />
              </Grid>
            )}
          </Grid>
        </Box>
      );
    } else if (haveE2eLendersResponded && showMoveForwardCard) {
      return (
        <Grid container spacing={2}>
          <MoveForwardCard onClick={handleMoveForwardAction} />
        </Grid>
      );
    }
  };

  const getLenderDetails = () => {
    const defaultP =
      'The next step in the process is done in the provider website. When you are finished there, your account will be updated here. See you soon!';
    const defaultImg = '/assets/e2e-redesign/images/formpiper-bird-blue.png';
    const lenderE2e =
      currentRedirectLender?.api_pipe_settings?.e2e_config?.config?.lender_details?.after_form_submission_redirect;
    return {
      p: lenderE2e?.paragraph ? lenderE2e.paragraph : defaultP,
      img: lenderE2e?.image_url ? lenderE2e.image_url : defaultImg,
    };
  };
  const modalDetails = getLenderDetails();

  return (
    <>
      <Seo title="Lender Responses" />
      <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
        <ThemedContainer>
          {showDevButton()}
          <PageIntro title={responseDetails.t} paragraph={responseDetails.p} />
          {allDeclined && (
            <>
              <Typography variant="body1" style={{ marginTop: '20px' }} paragraph>
                Visit the myFormPiper portal for more resources.
              </Typography>
            </>
          )}
          {!haveE2eLendersResponded && !showErrorPage && (
            <LoadingState title={'Processing'} paragraph={'We are processing your application'} />
          )}
          {handleRedirectOrOffers()}
        </ThemedContainer>
        <ResponseFooter allDeclined={allDeclined} />
        <RedirectModal
          open={openRedirectModal}
          onClose={closeRedirectModal}
          title="You are being redirected"
          paragraph={modalDetails.p}
          imageUrl={modalDetails.img}
        />
      </Box>
    </>
  );
}
