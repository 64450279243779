import {
  API_E2E_GET_FORM_SUBMISSIONS,
  API_E2E_GET_PROVIDER_STATUS,
  API_E2E_PATCH_AASO,
  API_E2E_POST_AASO_DETAILS,
  API_E2E_POST_FORM,
} from 'src/api/e2e-request-objects';
import { TsUrlParamsI } from 'src/api/query-logic/query-interfaces';
import { apiRequest } from 'src/api/request-handler';
import {
  API_GET_E2E_CONSUMER_FACING_FORM_DETAILS,
  API_GET_LOCATIONS,
  API_GET_STORE_LENDER_DISCLOSURES,
  API_GET_STORE_LENDER_DISCLOSURES_WITH_STORE_UUID,
} from 'src/api/request-objects';
import { e2eConfigLogic_buildAppConfigurationObject } from 'src/e2e-redesign/business-logic/e2e-configuration-object-logic';
import { e2eHandleErrors } from 'src/e2e-redesign/business-logic/e2e-handle-errors';
import { E2eClientErrorAggregate } from 'src/errors/error.interfaces';
import { AASO } from 'src/interfaces/aaso.interfaces';
import { Disclosure } from 'src/interfaces/disclosures.interfaces';
import { AppLogicObjectsResponseI, E2eAppConfigurationsI, E2eGlobalStore } from 'src/interfaces/e2e-state.interfaces';
import { ApplicationSubmissionStateDto, AppSubStatusE } from 'src/interfaces/submissions.interfaces';
import { ApiError, handleError } from 'src/utils/error';
import { fetchIPAddress } from 'src/utils/general';
import { create } from 'zustand';

import { ApplicationStage, PathType } from './enums/aaso.enums';

/**
 * Creating a new one bc the other one got away from us
 */
const useE2eStore = create<E2eGlobalStore>((set, get) => ({
  applicant: {
    first_name: '',
    last_name: '',
    email: '',
    mobile_phone: '',
  },
  store_uuid: '',
  e2e_form_id: '',
  aaso: {
    _id: undefined,
    application_state_id: '',
    current_stage: ApplicationStage.STARTED,
    verification: {
      is_verified: false,
      ip_address: '',
    },
    kick_off_details: [],
    current_path_id: undefined,
    past_path_ids: [],
    past_path_index: [],
    path_index: undefined, // the path they selected
    path_type: PathType.GoodCredit, // deprecate
    prime_eligible: false,
    disclosures: [],
    store_id: '',
    store_uuid: '',
    store_name: '',
    form_id: '',
    applicant_id: '',
    created_at: '',
    updated_at: '',
    selected_lender_id: null,
    e2e_form_id: '',
    e2e_job_details: [],
  },
  submissions: [],
  form_values: undefined,
  form_details: {},
  locations: [],
  franchise_settings: undefined,
  laa_settings: undefined,
  disclosures: [],
  fp_disclosures: [],
  lenderDisclosures: [],
  uniqueLenderLogos: [],
  lender_details: [],
  thank_you_page_details: undefined, // R:TODO E2E P1 - need to deprecate this as it's in two places
  // Logic Objects
  application_settings: undefined, // Add Types
  lender_ids: [],
  lenders: [],
  outside_form_details: undefined,
  store_details: undefined,
  store_settings: undefined,
  // Configuration object
  app_configurations: undefined,
  // functions
  initApplicationStore: (uuid: string, name: string, e2e_form_id: string) => {
    set(() => ({
      aaso: { ...get().aaso, store_uuid: uuid, store_name: name, e2e_form_id },
      store_uuid: uuid,
      e2e_form_id,
    }));
  },
  setThankYouPage: (data: any) => {
    set(() => ({
      thank_you_page_details: data,
    }));
  },
  setAppConfigurations: (data: E2eAppConfigurationsI) => {
    set(() => ({
      app_configurations: {
        ...get().app_configurations,
        ...data,
      },
    }));
    if (data.thank_you_page_details) {
      get().setThankYouPage(data.thank_you_page_details);
    }
  },
  updateFormValues: async (
    form: any,
    forceAddressVerification: boolean,
  ): Promise<{ success: boolean; form_id?: number; statusMessage?: string | null; address?: any }> => {
    try {
      const aaso = get().aaso;
      const { e2e_form_id, _id, store_uuid } = aaso;
      const res = await apiRequest(API_E2E_POST_FORM, {
        body: { aaso, form, force_address_verification: forceAddressVerification },
        params: { e2e_form_id, aaso_id: _id, store_uuid },
      });
      if (res) {
        return { success: true, form_id: res };
      } else {
        throw res;
      }
    } catch (e) {
      if (e instanceof Error && e.message === 'API_ERROR') {
        const apiError = e as ApiError;
        const body = apiError.body as E2eClientErrorAggregate;
        if (body?.errors) {
          e2eHandleErrors(body);
        }
        return { success: false, statusMessage: apiError.statusMessage || null, address: body?.address };
      } else if (e?.type) {
        return { success: false, statusMessage: null };
      } else {
        handleError(e);
        return { success: false, statusMessage: null };
      }
    }
  },
  getE2eSubmissions: async (aaso_id?: string, e2e_form_id?: string) => {
    if (aaso_id && e2e_form_id) {
      try {
        // const res = await apiRequest(API_GET_E2E_FORM_SUBMISSIONS, { params: { aaso_id } });
        const res = await apiRequest(API_E2E_GET_FORM_SUBMISSIONS, { params: { aaso_id, e2e_form_id } });
        if (res) {
          set(() => ({ submissions: [...res] }));
        }
      } catch (e) {}
    }
  },
  addSubmission: async (params, submissionStates: AppSubStatusE[], lenderId: number) => {
    const submissions = get().submissions;
    const lenderSubmission = submissions.find((s) => Number(s.lender_id) == Number(lenderId));
    if (submissions.length > 0 && lenderSubmission) {
      // check and make sure lenderSubmission hasn't already been completed
      if (submissionStates.includes(lenderSubmission.submission_state)) {
        // it's still in redirected state - grab the latest
        const { aaso_id, e2e_form_id, store_uuid } = params;
        const obj: any = {
          params: { aaso_id, e2e_form_id, store_uuid, lender_id: lenderId },
        };
        try {
          try {
            const res: ApplicationSubmissionStateDto = await apiRequest(API_E2E_GET_PROVIDER_STATUS, obj);

            if (res) {
              const updatedSubmissions: ApplicationSubmissionStateDto[] = [];
              if (submissions) {
                if (submissions.length > 0) {
                  // find the submission if it exists and replace it
                  for (const sub of submissions) {
                    if (Number(sub.lender_id) === Number(res.lender_id)) {
                      updatedSubmissions.push(res);
                    } else {
                      updatedSubmissions.push(sub);
                    }
                  }
                } else {
                  updatedSubmissions.push(res);
                }
              }
              set(() => ({ submissions: updatedSubmissions }));
            }
          } catch (e) {
            if (e instanceof Error && e.message === 'API_ERROR') {
              const apiError = e as ApiError;
              const body = apiError.body as E2eClientErrorAggregate;
              if (body?.errors) {
                e2eHandleErrors(body);
              }
            }
            throw e;
          }
        } catch (e) {
          // handleError(e);
          console.log(e);
          // throw new Error('Failed to kick off');
        }
      }
    }
  },
  updateAASO: async ({ data, sync }: { data: Partial<AASO>; sync: boolean }) => {
    const aaso = get().aaso;
    // R:TODO E2E P0 - don't use root.store_uuid - go off of aaso object or param (preferabbly param as that should always be there)
    if (sync && aaso._id) {
      try {
        const body = { _id: aaso._id, updateValues: data };
        const res = await apiRequest(API_E2E_PATCH_AASO, {
          body,
          params: {
            store_uuid: aaso.store_uuid,
            e2e_form_id: aaso.e2e_form_id,
            aaso_id: aaso._id,
            applicant_id: aaso.applicant_id,
          },
        });
        if (res) {
          set((state) => ({ aaso: { ...state.aaso, ...res } }));
        }
      } catch (e) {
        handleError(e);
      }
    } else {
      set((state) => ({ aaso: { ...state.aaso, ...data } }));
    }
  },
  setAASO: (data: any) => {
    // this is for the initial call
    if (data) {
      set(() => ({
        aaso: data,
      }));
    }
  },
  setDisclosures: (data: any) => {
    if (data) {
      set(() => ({
        disclosures: data,
      }));
    }
  },
  setFpDisclosures: (data: any) => {
    if (data) {
      set(() => ({
        fp_disclosures: data,
      }));
    }
  },
  setFormValues: (data: any) => {
    if (data) {
      set(() => ({
        form_values: data,
      }));
    }
  },
  setLocations: (data: any) => {
    if (data) {
      set(() => ({
        locations: data,
      }));
    }
  },
  setLogicObjects: (data: AppLogicObjectsResponseI, urlParams?: Partial<TsUrlParamsI>) => {
    if (data) {
      set(() => ({
        application_settings: data.application_settings,
        lender_ids: data.lender_ids,
        lenders: data.lenders,
        outside_form_details: data.outside_form_details,
        store_details: data.store_details,
        store_settings: data.store_settings,
        laa_settings: data.laa_settings,
        franchise_settings: data.franchise_settings,
      }));
      if (urlParams?.e2e_form_id) {
        const aaso = get().aaso;
        // now we need to build the configuration objects
        const appSettings = e2eConfigLogic_buildAppConfigurationObject(data, aaso, urlParams.e2e_form_id);
        get().setAppConfigurations(appSettings);
      }
    }
  },
  setLenderDetails: (data: any) => {
    if (data) {
      set(() => ({
        lender_details: data,
      }));
    }
  },
  getApplicationSettings: async () => {},
  setStoreUuid: () => {
    // set({ store_uuid });
  },
  createAaso: async (user: any, store_uuid: string, e2e_form_id: string) => {
    if (!get()?.aaso?._id) {
      if (store_uuid) {
        const AASO = await apiRequest(API_E2E_POST_AASO_DETAILS, {
          params: { applicant_id: user?.id, store_uuid, e2e_form_id },
        });

        AASO.verification = AASO?.verification || { is_verified: false, ip_address: '' };

        if (AASO?.verification?.ip_address !== get().aaso.verification.ip_address) {
          AASO.verification.is_verified = false;

          if (!AASO?.verification?.ip_address) {
            AASO.verification.ip_address = await fetchIPAddress();
          }
        }
        set((state) => ({ aaso: { ...state.aaso, ...AASO } }));
      }
    }
    return get().aaso;
  },
  getAasoByAasoId: async () => {
    // if (!get()?.aaso?._id) {
    //   try {
    //     const AASO = await apiRequest(API_GET_AASO, { params: { id: aaso_id } });
    //     set((state) => ({ aaso: { ...state.aaso, ...AASO } }));
    //   } catch (e) {
    //     handleError(e);
    //   }
    // }
    return get().aaso;
  },
  getFormDetails: async (store_uuid: string | undefined) => {
    try {
      const res = await apiRequest(API_GET_E2E_CONSUMER_FACING_FORM_DETAILS, { params: { store_uuid } });
      set(() => ({ form_details: res }));
    } catch (e) {
      handleError(e);
    }
  },
  getLocations: async () => {
    try {
      const res = await apiRequest(API_GET_LOCATIONS);
      set(() => ({ locations: res }));
    } catch (e) {
      handleError(e);
    }
  },
  getStoreLenderDisclosures: async (store_id: string) => {
    try {
      const res = await apiRequest(API_GET_STORE_LENDER_DISCLOSURES, { params: { store_id } });
      set(() => ({ lenderDisclosures: res }));
    } catch (e) {
      handleError(e);
    }
  },
  getStoreLenderDisclosuresWithStoreUUid: async (store_uuid: string) => {
    try {
      const res = await apiRequest(API_GET_STORE_LENDER_DISCLOSURES_WITH_STORE_UUID, { params: { store_uuid } });
      set(() => ({ lenderDisclosures: res }));
      const uniqueLogos = get()
        .lenderDisclosures.filter((disclosure) => disclosure.logo && disclosure.disclosure_type === 'lender')
        .reduce((acc: Disclosure[], curr: Disclosure) => {
          const exists = acc.some((disclosure) => disclosure.lender_id === curr.lender_id);
          if (!exists && curr.lender_id !== undefined && ![31, 90].includes(curr.lender_id)) acc.push(curr);
          return acc;
        }, [])
        .map((disclosure) => disclosure.logo)
        .slice(0, 6);
      set({ uniqueLenderLogos: uniqueLogos });
    } catch (e) {
      handleError(e);
    }
  },
}));

export default useE2eStore;
