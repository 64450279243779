import React from 'react';
import { Box, Button, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { CheckboxWithLabel } from 'formik-mui';
import { Seo } from 'src/components/seo';
import { E2EChunkedLenderDisclosuresI } from 'src/e2e-redesign/business-logic/e2e-disclosure-logic';
import { E2eLogicObjectsWithAasoParams } from 'src/e2e-redesign/business-logic/e2e-logic-utils';
import { providerDiscLogic_childFuncsAndVals } from 'src/e2e-redesign/views/ProviderDisclosures/logic/provider-disclosures-logic';
import { Disclosure } from 'src/interfaces/disclosures.interfaces';
import * as Yup from 'yup';

import PageIntro from '../../../components/PageIntro';
import SingleLogo from '../../../components/SingleLogo';
import ThemedContainer from '../../../components/ThemedContainer';
import styles from '../ProviderDisclosures.module.css';

export interface SingleProviderDisclosuresProps {
  selectedDisclosures: E2EChunkedLenderDisclosuresI | Disclosure | undefined;
  routingPackage: E2eLogicObjectsWithAasoParams;
}

export default function ProviderSingleDisclosure(props: SingleProviderDisclosuresProps) {
  const { selectedDisclosures, routingPackage } = props;
  const fnv = providerDiscLogic_childFuncsAndVals(routingPackage, selectedDisclosures);
  const { handleSingleDisclosureAccept, handleGoBackDisclosures } = fnv.fn;
  const { disclosures, showGoBackButton } = fnv.v;
  const theme = useTheme();
  if (!selectedDisclosures) return <></>;
  const logo = {
    src: disclosures?.logo || '',
    alt: disclosures?.name || '',
  };

  const initialValues = {
    agreeTerms: false,
    optInMarketing: false,
  };

  const validationSchema = Yup.object().shape({
    agreeTerms: Yup.boolean().oneOf([true], 'You must agree to the terms.'),
    optInMarketing: disclosures?.opt_in_marketing
      ? Yup.boolean().oneOf([true], 'You must opt in to marketing communications.')
      : Yup.boolean(),
  });

  return (
    <>
      <Seo title="Disclosure Certification" />

      <ThemedContainer>
        <PageIntro title="Disclosure Certification" />
        <Box mb={2} sx={{ display: 'flex', justifyContent: 'flex-start' }}>
          <SingleLogo image={logo} />
        </Box>
        <Typography paragraph variant="body1">
          By clicking “I AGREE” below you acknowledge the following:
        </Typography>
        <Box
          sx={{
            border: '1px solid #E0E8F5',
            borderRadius: '15px',
            padding: '10px',
            // height: { xs: '450px', md: '300px' },
            maxHeight: { xs: '450px', md: '300px' },
            overflowY: 'scroll',
            marginBottom: '20px',
            // TODO: Add themed scrollbar to a global css file if possible
            '&::-webkit-scrollbar': {
              width: '12px',
            },
            '&::-webkit-scrollbar-track': {
              background: '#ffffff',
            },
            '&::-webkit-scrollbar-thumb': {
              background: '#E0E8F5',
              borderRadius: '15px',
            },
            '&::-webkit-scrollbar-thumb:hover': {
              background: '#B0C4DE',
            },
          }}
        >
          {disclosures?.dynamic_link ?
            <iframe className={styles.dialogContent} src={disclosures?.dynamic_link} title={disclosures.name}  style={{ width: '100%', height: '100%', border: 'none' }} /> : <div
              className={styles.dialogContent}
              dangerouslySetInnerHTML={{ __html: disclosures?.document ? disclosures.document : '' }}
            />}
        </Box>
        <Formik
          key={disclosures?._id}
          initialValues={initialValues}
          validationSchema={validationSchema}
          enableReinitialize
          onSubmit={(values, { setSubmitting }) => {
            // TODO: Handle form submission
            handleSingleDisclosureAccept(values);
            setSubmitting(false);
          }}
        >
          {({ isSubmitting }) => (
            <Form>
              <Box mb={2}>
                <Field
                  component={CheckboxWithLabel}
                  name="agreeTerms"
                  type="checkbox"
                  Label={{ label: disclosures?.accept_text || 'I agree to the terms above.' }}
                />
                {/* <span style={{ color: '#677292' }}>{disclosures?.accept_text || 'I agree to the above terms.'}</span> */}
                <ErrorMessage
                  name="agreeTerms"
                  component="div"
                  render={(msg) => <div style={{ color: theme.palette.error.main }}>{msg}</div>}
                />
              </Box>
              {disclosures?.opt_in_marketing && (
                <Box mb={6}>
                  <Field
                    component={CheckboxWithLabel}
                    name="optInMarketing"
                    type="checkbox"
                    Label={{ label: 'I opt in to marketing communications.' }}
                  />
                  <ErrorMessage
                    name="optInMarketing"
                    component="div"
                    render={(msg) => <div style={{ color: theme.palette.error.main }}>{msg}</div>}
                  />
                </Box>
              )}
              {/* TODO: Make stack on mobile */}
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: { xs: 'column', md: 'row' },
                  justifyContent: { md: 'flex-start' },
                  alignItems: 'center',
                  gap: 2,
                }}
              >
                <Button variant="contained" color="warning" size="large" type="submit" disabled={isSubmitting}>
                  I Agree
                </Button>
                {/* R:TODO E2E P0 - if first disclosures do not go back */}
                {showGoBackButton && (
                  <Button
                    variant="text"
                    sx={{ color: theme.palette.text.primary, textDecoration: 'underline' }}
                    onClick={handleGoBackDisclosures}
                  >
                    Back
                  </Button>
                )}
              </Box>
            </Form>
          )}
        </Formik>
      </ThemedContainer>
    </>
  );
}
